<template>
    <div class="content">
      <div class="flex_item">
        <div style="margin-right: 15px;">单笔支付金额最大上限: </div>
        <el-input v-model="inputOne" type="number" placeholder="不填写为不限制" style="width: 400px"></el-input>
      </div>
      <div class="flex_item">
        <div style="margin-right: 15px;">单笔交易笔数最大上限: </div>
        <el-input v-model="inputTwo" type="number" placeholder="不填写为不限制" style="width: 400px"></el-input>
      </div>
      <div class="flex_item">
        <div style="margin-right: 15px;">消息推送: </div>
        <el-switch v-model="isPushRemind"></el-switch>
      </div>
      <div class="flex_item" v-if="isPushRemind">
        <div style="margin-right: 15px;">推送时间: </div>
        <el-time-picker v-model="pushRemindTime" size="medium" value-format="HH:mm:ss"></el-time-picker>
      </div>
      <div class="flex_item">
        <div style="margin-right: 15px;">0销售和退货限制: </div>
        <el-switch v-model="isSalesReturn"></el-switch>
      </div>
      <div class="flex_item">
        <div style="margin-right: 15px;">批量审批上报销售: </div>
        <el-switch v-model="isBatch"></el-switch>
      </div>
      <div class="flex_item">
        <div style="margin-right: 15px;">销售日期配置: </div>
        <el-switch v-model="isShowPeriod"></el-switch>
      </div>
      <template v-if="isShowPeriod">
        <div class="flex_item">
          <div style="margin-right: 15px;">时段范围配置: </div>
          <el-time-select
            v-model="beginPeriod"
            size="medium"
            :clearable="false"
            :picker-options="pickerOptions"
            placeholder="开始时间">
          </el-time-select>
          <span style="margin: 0 5px">-</span>
          <el-time-select
            v-model="endPeriod"
            size="medium"
            :clearable="false"
            :picker-options="pickerOptions"
            placeholder="结束时间">
          </el-time-select>
        </div>
        <div class="flex_item">
          <div style="margin-right: 15px;">默认时段: </div>
          <el-time-select
            v-model="defaultPeriod"
            size="medium"
            :clearable="false"
            :picker-options="pickerOptions"
            placeholder="开始时间">
          </el-time-select>
        </div>
      </template>
      <div class="flex_item">
        <div style="margin-right: 15px;">货品信息: </div>
        <el-switch v-model="isShowGoods"></el-switch>
      </div>
      <div class="flex_item">
        <div style="margin-right: 15px;">展示销售数据: </div>
        <el-switch v-model="isCollectSale"></el-switch>
        <div class="tip">注;接受第三方销售数据，展示在上报销售页面</div>
      </div>
      <div class="btn">
        <el-button type="primary" @click="save">保存</el-button>
        <el-button @click="back">取消</el-button>
      </div>
    </div>
</template>

<script>
import {setSaleConfig, getSaleConfig } from "../../../services/sysapi";

export default {
  name: "index",
  data(){
    return {
      pushRemindTime: '',
      isPushRemind:false,
      inputOne: null,
      inputTwo: null,
      isShowPeriod: false,
      beginPeriod: '',
      endPeriod: '',
      defaultPeriod: '',
      isSalesReturn: false,
      isBatch: false,
      isShowGoods: false,
      isCollectSale: false,
      config:{
        verified:false
      },
      pickerOptions: {
        start: '00:00',
        step: '00:30',
        end: '23:30'
      }
    }
  },
  created(){
    this._getConfig()
  },
  methods:{
    save(){
      if((this.inputOne || this.inputOne == 0) && this.inputOne<1 || this.inputOne>9999999999.99){
        this.$message.warning('单笔支付金额最大上限在1到9999999999.99')
        return
      }
      if((this.inputOne || this.inputOne == 0) && !/^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/.test(this.inputOne)){
        this.$message.warning('单笔支付金额最大上限小数点最多两位')
        return
      }
      if((this.inputTwo || this.inputTwo == 0) && this.inputTwo<1 || this.inputTwo>99999){
        this.$message.warning('单笔交易笔数最大上限在1到99999')
        return
      }
      if((this.inputTwo || this.inputTwo == 0) && !(/(^[1-9]\d*$)/.test(this.inputTwo))){
        this.$message.warning('单笔交易笔数最大上限为正整数')
        return
      }
      if(this.isShowPeriod && (this.beginPeriod >= this.endPeriod)) {
        return this.$message.warning('开始时间必须小于结束时间')
      }
      if(this.isShowPeriod && (this.defaultPeriod > this.endPeriod || this.defaultPeriod < this.beginPeriod)) {
        return this.$message.warning('默认时段需要在时段范围配置内')
      }
      this._setConfig()
    },
    back(){
      this._getConfig()
    },
    _getConfig(){
      getSaleConfig().then((res)=>{
        if(res.code==200){
          this.inputOne = res.data.maxMoney==-1 ? null : res.data.maxMoney
          this.inputTwo = res.data.maxNumber==-1 ? null : res.data.maxNumber
          this.isPushRemind = res.data.isPushRemind
          this.pushRemindTime = res.data.pushRemindTime
          this.isShowPeriod = res.data.isShowPeriod
          this.isSalesReturn = res.data.isSalesReturn
          this.beginPeriod = res.data.beginPeriod.slice(0,5)
          this.endPeriod = res.data.endPeriod.slice(0,5)
          this.defaultPeriod = res.data.defaultPeriod.slice(0,5)
          this.isBatch = res.data.isBatch
          this.isShowGoods = res.data.isShowGoods
          this.isCollectSale = res.data.isCollectSale
        }else{
          this.$message.warning(res.msg)
        }
      })
    },
    _setConfig(){
      let param = {
        maxMoney: this.inputOne ? this.inputOne : -1,
        maxNumber:this.inputTwo ? this.inputTwo : -1 ,
        isPushRemind: this.isPushRemind,
        isShowPeriod: this.isShowPeriod,
        isSalesReturn: this.isSalesReturn,
        isBatch: this.isBatch,
        isShowGoods: this.isShowGoods,
        isCollectSale: this.isCollectSale
      }
      if (this.isPushRemind) {
        if (!this.pushRemindTime) {
          this.$message.warning('请配置推送时间')
          return
        }
        param.pushRemindTime = this.pushRemindTime
      }
      if(this.isShowPeriod) {
        param.beginPeriod = this.beginPeriod
        param.endPeriod = this.endPeriod
        param.defaultPeriod = this.defaultPeriod
      }
      setSaleConfig(param).then((res)=>{
        if(res.code==200){
          this._getConfig()
          this.$message.success('保存成功!')
        }else{
          this.$message.warning(res.msg)
        }
      })
    },
    _changeBatchStatus(){
      updateSaleConfig({isBatch:this.isBatch}).then(res=>{
        if(res.code==200){
          this._getConfig()
        }else{
          this.$message.warning(res.msg)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  background: #fff;
  border-radius: 6px;
  padding: 20px;

  .config_item {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  .flex_item {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    > div:first-of-type {
      width: 160px;
      text-align: right;
    }
  }
  .config_item ::v-deep .el-input__inner{
    width: 300px;
    height: 40px;
    line-height: 40px;
  }
  .config_item ::v-deep .el-input{
    width: 400px;
  }
  .btn{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // position: absolute;
    // bottom: 20px;
    // right: 20px;
  }
}

.tip {
  margin-left: 20px;
}

</style>